import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import QuestionsGrid from "./QuestionsGrid";
import { UserInfo } from "./UserInfo";

const UserResults = ({ user, setSelectedUser, responseList, setHeader }) => {
  const { signedIn } = useContext(AuthContext);

  useEffect(() => {
    setHeader(<></>);
  }, [setHeader]);

  if (!signedIn) return <Navigate to="/login" />;

  return (
    <>
      <UserInfo user={user} setSelectedUser={setSelectedUser} />
      <QuestionsGrid result={responseList} />
    </>
  );
};

export default UserResults;
