import React from "react";
import {
  Card,
  CardBody,
  Text,
  Grid,
  GridItem,
  IconButton,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useIsMobile } from "../../utils/isMobile";
import { useNavigate } from "react-router-dom";

export const UserInfo = ({ user, setSelectedUser }) => {
  const [isMobile] = useIsMobile();
  const navigate = useNavigate();

  return (
    <>
      <Card
        sx={{
          rounded: "lg",
          overflow: "hidden",
          border: "1px solid #2F2F2F",
          bg: "transparent",
          width: "90%",
          mx: "auto",
        }}
      >
        <CardBody fontSize="15px" padding="1rem">
          <Grid templateColumns={isMobile ? "1" : "repeat(4, 1fr)"} gap={6}>
            <GridItem w="100%">
              <Text display={"flex"}>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Name:
                </Text>
                <Text
                  textDecoration={"underline"}
                  cursor={"pointer"}
                  onClick={() => {
                    setSelectedUser(user);
                    navigate("/dashboard/user-results");
                  }}
                >
                  {user.first_name} {user.last_name}
                </Text>
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Email:
                </Text>
                {user.email}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Job Title:
                </Text>
                {user.position}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Industry:
                </Text>
                {user.industry}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Phone:
                </Text>
                {user.phone}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Report Link:
                </Text>
                <IconButton
                  icon={<ExternalLinkIcon />}
                  boxSize={4}
                  onClick={() =>
                    navigate(`/results/${user.email}`, {
                      state: { user: user },
                    })
                  }
                />
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Date:
                </Text>
                {new Date(user.updated_at).toLocaleDateString()}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text>
                <Text paddingRight="1rem" as="b" color="rgba(0, 168, 240, 1)">
                  Time:
                </Text>
                {new Date(user.updated_at).toLocaleTimeString()}
              </Text>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </>
  );
};
