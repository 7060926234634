export async function signIn(creds) {
  return fetch(
    "https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/authenticate-admin",
    {
      method: "POST",
      body: JSON.stringify(creds),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
