import {
  Box,
  Center,
  Circle,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";
import styled from "@emotion/styled";

const stages = [
  {
    label: "Climate enthusiast",
    desc: "At this stage, organisations are typically just embarking on their decarbonisation journey and hence have the greatest potential to transform and pick up some quick wins.",
    position: 5,
  },
  {
    label: "Climate conscious",
    desc: "At this stage, organisations are typically aware of the impact of climate change and have taken steps towards mitigation. They can now accelerate their journey, optimising their strategy and roadmap.",
    position: 35,
  },
  {
    label: "Climate influencer",
    desc: "At this stage, organisations have come far with their climate action and typically reaped some early rewards. They are now entering the advanced phase of their Net Zero journey.",
    position: 65,
  },
  {
    label: "Climate leader",
    desc: "At this stage, organisations are paving the way with their climate action and motivating others to follow suit.",
    position: 95,
  },
];

const StageLabel = styled(Text)`
  position: absolute;
  bottom: 32px;
  width: max-content;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

function StageSlider({ stageIndex, isMobile }) {
  const highlightedStage = stages[stageIndex];

  return (
    <VStack
      w="100%"
      maxW="1338px"
      px={isMobile ? "35px" : "70px"}
      pt={isMobile ? "60px" : "90px"}
      pb={isMobile ? "25px" : "40px"}
      align="center"
      spacing={12}
      bg="transparent"
      borderWidth="1px"
      borderColor="rgb(255 255 255 / 20%)"
      borderRadius={32}
    >
      <Slider value={highlightedStage.position} min={0} max={100}>
        <StageLabel id="stage-2">
          <Text opacity={0.7} color={"white"}>
            {highlightedStage?.label}
          </Text>
        </StageLabel>
        {stages.map((stage) => {
          return (
            <SliderMark value={stage.position} id="stagelabel">
              <Center>
                <StageLabel>
                  <Text
                    opacity={0.7}
                    color={
                      stage.label === highlightedStage.label
                        ? "white"
                        : "onSurface.light.disabled"
                    }
                  >
                    {stage.label}
                  </Text>
                </StageLabel>
                <Box
                  bg="onSurface.light.disabled"
                  w="1px"
                  h={6}
                  pos="absolute"
                  bottom={1}
                />
              </Center>
            </SliderMark>
          );
        })}
        <SliderTrack
          bg="linear-gradient(270deg, #E34988 -0.01%, #9E2BAC 17.7%, #6E30D1 43.74%, #5374E3 66.66%, #73F2FF 99.99%)"
          h={2}
          borderRadius="4px"
        >
          <SliderFilledTrack bg="transparent" />
        </SliderTrack>
        <SliderThumb bg="transparent">
          <Circle
            borderWidth="5px"
            borderColor="black"
            bg="white"
            size="26px"
          />
          <Box bg="white" w="1px" h={6} pos="absolute" bottom={3} />
        </SliderThumb>
      </Slider>
      <Text color="onSurface.light.medium" textAlign={"left"}>
        {highlightedStage.desc}
      </Text>
    </VStack>
  );
}

export default StageSlider;
