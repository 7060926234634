import {
  Button,
  Flex,
  Image,
  Box,
  Heading,
  Spacer,
  Text,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getStage } from "../../utils/getScores";
import StageSlider from "./StageSlider";
import BarChart from "./BarChart";
import RadarChart from "./RadarChart";
import { useLocation, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { capitalize } from "../../utils/capitalize";
import { ExternalLinkIcon, LinkIcon, DownloadIcon } from "@chakra-ui/icons";
import { categories } from "../../constants/categories";
import { useIsMobile } from "../../utils/isMobile";
import { getResult } from "../api/getResult";

const ResultsPage = (props) => {
  let { setHeader, pillarAnalysisData } = props;
  let { encryptedString } = useParams();

  const [user, setUser] = useState({});
  const location = useLocation();
  const [isMobile] = useIsMobile();
  const [stageIndex, setStageIndex] = useState(0);
  const [showLink, setShowLink] = useState({
    tool: false,
    results: false,
  });
  const [showError, setShowError] = useState(false);
  const [hoverPillarText, setHoverPillarText] = useState(
    "The Carbon Maturity Assessment report evaluates your organisation's sustainability practices and provides key recommendations for improvement. The assessment recognizes your commitment to reducing carbon emissions and identifies areas of focus to enhance sustainability efforts."
  );

  useEffect(() => {
    setHeader(
      <>
        <Spacer />
        <Flex gap="15px" justifyContent="end" alignItems="left">
          {!showLink.tool && (
            <a href="mailto:Expect?subject=Assess%20your%20Carbon%20Maturity%20with%20this%20Diagnostic%20Tool&body=Hey%20There%2CI%20wanted%20to%20introduce%20you%20to%20Diagnostic%20Tool%2C%20which%20assesses%20an%20organisation's%20carbon%20maturity%20in%203-5%20mins.%20It%20captures%20data%20regarding%20your%20carbon%20emissions%20and%20help%20in%20making%20future%20more%20sustainable.%20Try%20it%20out%20with%20a%20free%20demo%20at%20https%3A%2F%2Fdiagnostic.expectai.com%2F.%20I%20believe%20Diagnostic%20Tool%20can%20help%20transform%20our%20responsibility%20towards%20achieving%20emissions%20targets.%20Feel%20free%20to%20reach%20out%20to%20zero%40xct.ai%20if%20you%20have%20any%20questions.%0D%0ABest%20regards%2C%0D%0AExpect%20Team.">
              <Button colorScheme="grey" variant="outline" id="share">
                Share Tool
                <ExternalLinkIcon mx="5px" w={4} h={4} />
              </Button>
            </a>
          )}
          {showLink.tool && (
            <Button colorScheme="grey" variant="outline" id="share">
              Link copied
              <LinkIcon mx="5px" w={4} h={4} />
            </Button>
          )}
          {!showLink.results && (
            <Button
              colorScheme="grey"
              variant="outline"
              onClick={() => shareLink("results")}
              id="share"
            >
              Share Results
              <ExternalLinkIcon mx="5px" w={4} h={4} />
            </Button>
          )}
          {showLink.results && (
            <Button colorScheme="grey" variant="outline" id="share">
              Link copied
              <LinkIcon mx="5px" w={4} h={4} />
            </Button>
          )}
        </Flex>
      </>
    );
  }, [showLink, setHeader]);

  useEffect(() => {
    async function getUserScores() {
      if (location?.state?.user) {
        location.state.user.score = location.state.user.score.map((x) => [
          capitalize(x[0]),
          x[1],
        ]);

        const updatedScore = location.state.user?.score?.reduce(
          (acc, sum) => acc + sum[1],
          0
        );
        setStageIndex(
          getStage(parseFloat((updatedScore / categories.length).toFixed(2)))
        );
        setUser(location.state.user);
      }
      if (!location?.state?.user && encryptedString.length > 0) {
        try {
          let result = await getResult(encryptedString);
          result.score = result.score.map((x) => [capitalize(x[0]), x[1]]);

          const updatedScore = result.score?.reduce(
            (acc, sum) => acc + sum[1],
            0
          );

          setStageIndex(
            getStage(parseFloat((updatedScore / categories.length).toFixed(2)))
          );
          setUser(result);
        } catch (err) {
          console.log(err);
          setShowError(true);
        }
      }
    }
    getUserScores();
  }, [encryptedString]);

  const shareLink = (prop) => {
    const link = prop === "tool";

    if (link) {
      setShowLink({ ...showLink, tool: true });
      navigator.clipboard.writeText(window.location.href.split("/r")[0]);
    } else {
      setShowLink({ ...showLink, results: true });
      navigator.clipboard.writeText(window.location.href);
    }

    setTimeout(() => {
      setShowLink({ tool: false, results: false });
    }, 6000);
  };

  const getScoreRange = (score, categoryNo) => {
    return score >= 0 && score <= 3.5
      ? pillarAnalysisData[categoryNo]["0-3.5"]
      : score > 3.5 && score <= 6.5
      ? pillarAnalysisData[categoryNo]["3.5-6.5"]
      : score > 6.5 && score <= 8
      ? pillarAnalysisData[categoryNo]["6.5-8"]
      : pillarAnalysisData[categoryNo]["8-10"];
  };

  const pillarData = {
    [categories[0]]: (score) => getScoreRange(score, 0),
    [categories[1]]: (score) => getScoreRange(score, 1),
    [categories[2]]: (score) => getScoreRange(score, 2),
    [categories[3]]: (score) => getScoreRange(score, 3),
    [categories[4]]: (score) => getScoreRange(score, 4),
    [categories[5]]: (score) => getScoreRange(score, 5),
    [categories[6]]: (score) => getScoreRange(score, 6),
    [categories[7]]: (score) => getScoreRange(score, 7),
    [categories[8]]: (score) => getScoreRange(score, 8),
  };

  if (showError)
    return (
      <Text align="center" fontSize="1.5rem">
        Failed to load results
      </Text>
    );

  return (
    <>
      {isEmpty(user) && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      {!isEmpty(user) && (
        <VStack h="100%" gap={20} id="finalReport">
          <VStack
            textAlign={isMobile ? "left" : "center"}
            gap={isMobile ? "34px" : "56px"}
            width="100%"
            alignItems={"center"}
          >
            <Heading fontSize="48px" fontWeight={300} textAlign={"center"}>
              {user.first_name}, here are your results!
            </Heading>
            <Text
              fontSize={["14px", "20px", "20px"]}
              fontWeight={500}
              width={isMobile ? "300px" : "900px"}
            >
              The results are an assessment of your current strengths and
              highlight the areas for improvement: the higher your scores, the
              better.
            </Text>
            <Button
              onClick={() => {
                window.print();
              }}
              id="download"
            >
              Download Report <DownloadIcon marginLeft={"5%"} />
            </Button>
            <StageSlider stageIndex={stageIndex} isMobile={isMobile} />
            <VStack
              w="100%"
              maxW={"1338px"}
              px={isMobile ? "35px" : "70px"}
              py="40px"
              align={isMobile ? "left" : "center"}
              spacing={12}
              bg="transparent"
              borderWidth="1px"
              borderColor="rgb(255 255 255 / 20%)"
              borderRadius={32}
            >
              <Text color="onSurface.light.medium" textAlign={"left"}>
                The Carbon Maturity Assessment report evaluates your
                organisation's sustainability practices and provides key
                recommendations for improvement. The assessment recognizes your
                commitment to reducing carbon emissions and identifies areas of
                focus to enhance sustainability efforts.
              </Text>
              <Text color="onSurface.light.medium" textAlign={"left"}>
                Recommendations include improving climate awareness and
                communication, adopting advanced measurement tools, fostering
                stronger engagement and partnerships, establishing long-term
                financial commitments, optimizing energy efficiency and
                renewable energy utilization, promoting transparency and
                collaboration in the supply chain, refining sustainability
                strategies, leveraging data-driven analysis, and embracing
                technology for automation and compliance. Implementing the
                recommendations will strengthen your sustainability efforts,
                drive meaningful change, and position your organisation as a
                leader in sustainable practices.
              </Text>
            </VStack>

            <Flex
              w={"100%"}
              maxW={"1338px"}
              h="auto"
              px={["20px", "20px", "20px", "30px", "40px"]}
              gap={["0px", "0px", "0px", "0px", "50px"]}
              borderWidth="1px"
              borderColor="rgb(255 255 255 / 20%)"
              borderRadius={32}
              id="grid"
              flexDirection={"column"}
            >
              <Flex
                h="auto"
                gap={["0px", "5px", "5px", "5px", "0px"]}
                alignItems="center"
                id="chart-align"
                flexDirection={["column", "column", "column", "column", "row"]}
              >
                <BarChart
                  setText={setHoverPillarText}
                  pillarData={pillarData}
                  scores={user.score}
                  isMobile={isMobile}
                />
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    fontSize={isMobile ? "11px" : "13px"}
                    mt={isMobile ? "20px" : "50px"}
                    textAlign="left"
                    mx={isMobile ? "20px" : "50px"}
                  >
                    Please find more details about each category when you hover
                    your numerical score for each section.
                  </Text>
                  <RadarChart scores={user.score} benchmark={5} />
                </Flex>
              </Flex>

              <Flex h="auto" gap="10px" flexDirection={"column"}>
                <Text
                  color="onSurface.light.medium"
                  align="left"
                  mt="-5%"
                  id="toplevel"
                  h="auto"
                  textAlign="left"
                >
                  {hoverPillarText}
                </Text>
                <Text
                  color="onSurface.light.medium"
                  align="left"
                  h="auto"
                  mb="40px"
                  id="scores"
                  textAlign="left"
                >
                  The scores on the higher spectrum show the commitment to
                  sustainability along those pillars. Scores of greater than 5
                  are higher than the benchmark. Your organistion is better in
                  those areas than most organsiations.
                </Text>
              </Flex>
              <VStack id="pillar" mt={isMobile ? "250px" : null}>
                {user?.score?.map((category, index) => {
                  return (
                    <Box
                      key={index}
                      display="flex"
                      width={"100%"}
                      id="category"
                      justifyContent={"space-between"}
                      flexDirection={"column"}
                    >
                      <Box>
                        <Image
                          src={"/icons/sections/" + category[0] + ".svg"}
                          opacity={0.7}
                          alt={category[0]}
                          height={"10"}
                        />
                        <Text textAlign="left" width={"40%"}>
                          {category[0]}
                        </Text>
                      </Box>
                      <Text textAlign="left" width={"100%"} height={"140px"}>
                        {pillarData[category[0]](
                          Math.round(category[1] * 10) / 10
                        )}
                      </Text>
                    </Box>
                  );
                })}
              </VStack>
            </Flex>
          </VStack>
          <VStack gap={20}>
            <Text
              fontWeight="bold"
              lineHeight="1.17"
              textTransform="capitalize"
              fontSize={["1.5em", "2em", "3em"]}
              align="center"
            >
              Start your{" "}
              <Text color="primary.primary" as="span">
                decarbonisation journey
              </Text>{" "}
              today
            </Text>
            <Button
              variant="primary"
              as="a"
              href="https://calendly.com/amandeep-singh-7"
              target="_blank"
            >
              Get started
            </Button>
          </VStack>
          <Text
            color="onSurface.light.medium"
            align="left"
            mb="48px"
            id="footer"
            fontSize="11px"
          >
            © 2023 Exponential Climate Technologies Ltd. All rights reserved The
            following are trademarks of Exponential Climate Technologies Ltd;
            UNA™ as our Climate Action Platform, Metamorphosis™ as our design
            led customer process, Expect™ as our company brand name,
            Expectopedia™ as our knowledge base (open sourced). For further
            information on out T and C's please visit: expectai.com
          </Text>
        </VStack>
      )}
    </>
  );
};

export default ResultsPage;
