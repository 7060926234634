import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AppContainer from "./components/General/AppContainer";
import QuestionPage from "./components/Questions/QuestionPage";
import StartPage from "./components/Start/StartPage";
import Papa from "papaparse";
import UserForm from "./components/Questions/UserForm";
import ResultsPage from "./components/Results/ResultsPage";
import InitialForm from "./components/Questions/InitialForm";
import EmailSuccess from "./components/EmailSuccess/EmailSuccess";
import Dashboard from "./components/Dashboard/Dashboard";
import { AdminLogin } from "./components/AdminLogin/AdminLogin";
import { Version } from "./components/Version/Version";
import ReactGA from "react-ga";
import UserResults from "./components/Dashboard/UserResults";
import AuthProvider from "./AuthProvider";
import OtpValidation from "./components/OtpValidation/OtpValidation";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

const App = () => {
  const isStartPage = window.location.pathname === "/start";
  const [header, setHeader] = useState(() =>
    isStartPage ? <Version /> : <></>
  );
  const [questions, setQuestions] = useState([{}]);
  const [pillarResponse, setPillarResponse] = useState([]);
  const [answers, setAnswers] = useState([""]);
  const [userFormData, setUserFormData] = useState({});
  const [result, setResult] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [responseList, setResponseList] = useState([]);
  const [dayFilter, setDayFilter] = useState("weekly");
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    fetch("/data//diagnosticQuestions.csv").then((response) => {
      if (response.status === 200) {
        const reader = response.body?.getReader();
        const decoder = new TextDecoder("utf-8");

        reader?.read().then((text) => {
          const csv = decoder.decode(text.value);
          const parseOptions = {
            skipEmptyLines: true,
            header: true,
          };
          const questions = Papa.parse(csv, parseOptions).data; // object with { dat }
          questions.forEach(
            (question) => (question.categories = question.categories.split(","))
          );
          setQuestions(questions);
          setAnswers(Array(questions.length).fill(""));
        });
      }
    });
  }, []);

  useEffect(() => {
    fetch("/data//pillarAnalysis.csv").then((response) => {
      if (response.status === 200) {
        const reader = response.body?.getReader();
        const decoder = new TextDecoder("utf-8");

        reader?.read().then((text) => {
          const csv = decoder.decode(text.value);
          const pillarAnalysisData = Papa.parse(csv, { header: true }).data; // object with { dat }
          setPillarResponse(pillarAnalysisData);
        });
      }
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <AppContainer header={header} id="finalReport">
          <Routes>
            <Route path="" element={<Navigate to="/start" replace />} />
            <Route path="start" element={<StartPage setHeader={setHeader} />} />
            <Route
              path="/questions/1"
              element={
                <InitialForm
                  setHeader={setHeader}
                  questions={questions}
                  userFormData={userFormData}
                  setUserFormData={setUserFormData}
                />
              }
            />
            <Route
              path={`/questions/${
                questions.length > 0 ? questions.length + 2 : ""
              }`}
              element={<Navigate to="/final" replace />}
            />
            <Route
              path="/final"
              element={
                <UserForm
                  setHeader={setHeader}
                  questions={questions}
                  answers={answers}
                  userFormData={userFormData}
                  setUserFormData={setUserFormData}
                />
              }
            />
            <Route
              path="/questions/:page"
              element={
                <QuestionPage
                  setHeader={setHeader}
                  questions={questions}
                  answers={answers}
                  setAnswers={setAnswers}
                  userFormData={userFormData}
                />
              }
            />
            <Route
              path="/results/:encryptedString"
              element={
                <ResultsPage
                  questions={questions}
                  pillarAnalysisData={pillarResponse}
                  setHeader={setHeader}
                  answers={answers}
                  userFormData={userFormData}
                />
              }
            />
            <Route
              path="/success"
              element={<EmailSuccess setHeader={setHeader} />}
            />
            <Route
              path="/otp-validation"
              element={<OtpValidation setHeader={setHeader} />}
            />
            <Route path="/login" element={<AdminLogin />} />
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  setHeader={setHeader}
                  setResult={setResult}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                  setDayFilter={setDayFilter}
                  setResponseList={setResponseList}
                  setShowLoader={setShowLoader}
                  dayFilter={dayFilter}
                  responseList={responseList}
                  showLoader={showLoader}
                />
              }
            />
            <Route
              path="/dashboard/user-results"
              element={
                <UserResults
                  setDayFilter={setDayFilter}
                  setHeader={setHeader}
                  user={selectedUser}
                  setSelectedUser={setSelectedUser}
                  responseList={responseList}
                />
              }
            />
          </Routes>
        </AppContainer>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
