export const getResult = async (encryptedString) => {
  try {
    const response = await fetch(
      `https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/survey/encrypted_string/${encryptedString}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};
