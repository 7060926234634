import { Button, Flex, IconButton, Image, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { generateOTP, sendResults } from "../api/sendResults";
import { getScores } from "../../utils/getScores";
import { useIsMobile } from "../../utils/isMobile";
import { categories } from "../../constants/categories";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import { getTitle } from "../../utils/getTitle";

const QuestionFooter = ({
  questionId,
  isStart,
  isEnd,
  disableShowResults,
  disableNext,
  rightSide,
  questions,
  answers,
  userFormData,
}) => {
  const parsedQuestionId = Number(questionId);
  const navigate = useNavigate();
  const [isMobile] = useIsMobile();
  const [loader, setLoader] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker("submit form");

  const SkipButton = () => {
    if (isStart || isEnd) return <></>;
    return (
      <Link to={`/questions/${parsedQuestionId + 1}`}>
        <Button
          variant="outline"
          w={isMobile ? "80px" : "148px"}
          position={"absolute"}
          right={0}
          bottom={0}
        >
          Skip
        </Button>
      </Link>
    );
  };

  const saveResults = async () => {
    setLoader(true);
    let { categoryScores, aggregate } = getScores(
      questions,
      answers,
      categories
    );
    let questionResponseObj = questions.map((x, i) => {
      return { question_id: i + 1, answer: answers[i] };
    });

    let res = await sendResults(
      userFormData,
      categoryScores,
      questionResponseObj
    );

    if (res.ok) {
      const { company, position, email, first_name, last_name } = userFormData;
      let title = getTitle(Number(aggregate));
      let data = {
        receiver_email: email,
        sender_email: "zero@xct.ai",
        sender_name: "Expect AI",
        receiver_name: first_name,
        score: parseFloat(aggregate.toFixed(2)),
        reportLinkId: localStorage.getItem("surveyId"),
        title,
        position,
        company,
        congrats:
          "Congratulations, your decarbonisation journey is underway. You're thinking about awareness & communication and have made a start to understanding your emissions. Articulating your objectives and translating those in to action will accelerate your progress from here.",
      };
      let OTPDataPayload = {
        email,
        first_name,
        last_name,
      };
      let result = await generateOTP(OTPDataPayload);
      if (result.ok) {
        setLoader(false);
        navigate("/otp-validation", {
          state: { emailData: data, otpPayload: OTPDataPayload },
          replace: true,
        });
      }
    }
    gaEventTracker("call");
  };

  const GetResultsButton = () => {
    if (!isEnd) return <></>;
    return (
      <Button
        variant="primary"
        disabled={disableShowResults}
        isLoading={loader}
        loadingText="Submitting"
        onClick={() => {
          saveResults();
        }}
      >
        Submit
      </Button>
    );
  };

  return (
    <Flex position={"relative"} w={"100%"} mt={"auto"}>
      <Flex
        position={"relative"}
        gap={isMobile ? null : "24px"}
        marginLeft={!isStart && !isEnd ? (isMobile ? null : "2.5%") : null}
        marginTop={!isStart && !isEnd ? (isMobile ? null : "2.1%") : null}
      >
        <Link
          to={
            parsedQuestionId > 1
              ? "/questions/" + (parsedQuestionId - 1)
              : "/start"
          }
        >
          <IconButton
            cursor="pointer"
            aria-label="back button"
            icon={<Image src="/icons/chevronLeft.svg" />}
            variant="ghost"
          />
        </Link>
        <Link
          to={
            disableNext || isEnd ? "#" : "/questions/" + (parsedQuestionId + 1)
          }
        >
          <IconButton
            aria-label="next button"
            icon={<Image src="/icons/chevronRight.svg" />}
            variant="ghost"
            cursor="pointer"
            disabled={disableNext || isEnd}
          />
        </Link>
      </Flex>
      <Spacer />
      <SkipButton />
      <GetResultsButton />
      {rightSide}
    </Flex>
  );
};

export default QuestionFooter;
