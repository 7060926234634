import { Flex, Grid, Image, Progress, Text } from "@chakra-ui/react";
import React from "react";

const BarChart = ({ scores, setText, pillarData, isMobile }) => {
  const handleHover = (val) => {
    setText(val);
  };

  const handleHoverOut = () => {
    setText(
      "The Carbon Maturity Assessment report evaluates your organisation's sustainability practices and provides key recommendations for improvement. The assessment recognizes your commitment to reducing carbon emissions and identifies areas of focus to enhance sustainability efforts."
    );
  };

  return (
    <Flex
      direction="column"
      gap="10px"
      id="bar"
      pt={"34px"}
      pb={isMobile ? null : "34px"}
    >
      {scores.map((category, index) => (
        <Grid
          templateColumns={isMobile ? "auto" : "repeat(2, 1fr)"}
          templateRows={isMobile ? "repeat(2, 1fr)" : null}
          gap={isMobile ? "2px" : "24px"}
          alignItems="center"
          key={category[0]}
          _hover={{ cursor: "pointer" }}
          onMouseEnter={() =>
            handleHover(
              pillarData[category[0]](Math.round(category[1] * 10) / 10)
            )
          }
          onMouseLeave={handleHoverOut}
        >
          <Flex alignItems="center" gap="16px" textAlign="left">
            <Image
              src={"/icons/sections/" + category[0] + ".svg"}
              opacity={0.7}
              alt={category[0]}
            />
            {category[0]}
          </Flex>
          <Flex alignItems="center" gap="24px">
            <Progress w="100%" borderRadius="4px" value={category[1] * 10} />
            <Text color="secondary.secondary" opacity={0.7}>
              {Math.round(category[1] * 10) / 10 /* value to 1dp */}
            </Text>
          </Flex>
        </Grid>
      ))}
    </Flex>
  );
};

export default BarChart;
