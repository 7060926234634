import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import QuestionFooter from "./QuestionFooter";
import QuestionHeader from "./QuestionHeader";
import QuestionNumber from "./QuestionNumber";

const UserForm = ({
  setHeader,
  questions,
  answers,
  userFormData,
  setUserFormData,
}) => {
  const totalPages = questions.length + 2;
  const [formStart, setFormStart] = useState({
    email: false,
    first_name: false,
    last_name: false,
    phone: false,
  });
  const [isError, setIsError] = useState({
    email: false,
    first_name: false,
    last_name: false,
    phone: false,
  });

  const checkFormStarted = () => {
    return formStart.email && formStart.first_name && formStart.last_name;
  };

  useEffect(() => {
    setHeader(
      <QuestionHeader
        questionId={totalPages}
        category="Your details"
        questionsLength={totalPages}
      />
    );
  }, [setHeader, totalPages]);

  const validations = (name, value) => {
    if (
      (name === "phone" &&
        /^\+?[0-9]{4,15}$/.test(value) === false &&
        value !== "") ||
      (name !== "phone" &&
        (value === "" ||
          (name === "email" &&
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) === false)))
    ) {
      setIsError({
        ...isError,
        [name]: true,
      });
    } else {
      setIsError({
        ...isError,
        [name]: false,
      });
    }
    setUserFormData({
      ...userFormData,
      [name]: value,
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormStart({
      ...formStart,
      [name]: true,
    });
    validations(name, value);
  };

  const checkFormComplete = () => {
    return (
      isError.email || isError.first_name || isError.last_name || isError.phone
    );
  };

  return (
    <>
      <Center h="100%">
        <Flex
          w="900px"
          direction="column"
          textAlign={"center"}
          gap={["0.5em", "1em", "auto", "auto", "3em"]}
        >
          <QuestionNumber number={totalPages} max={totalPages} />
          <Flex
            direction={"column"}
            gap="2em"
            alignItems={"flex-start"}
            padding="32px"
            borderColor="primary.primary"
            borderWidth={"1px"}
            borderRadius="16px"
          >
            <FormControl isInvalid={isError.email}>
              <Input
                onChange={onChange}
                placeholder="Email address"
                type="email"
                variant="flushed"
                name="email"
                size="lg"
                borderColor={"onSurface.light.low"}
              />
              {isError.email ? (
                <FormErrorMessage>Email is invalid.</FormErrorMessage>
              ) : (
                ""
              )}
            </FormControl>
          </Flex>
          <SimpleGrid columns={2} gap="1em">
            <FormControl isInvalid={isError.first_name}>
              <Input
                onChange={onChange}
                name="first_name"
                placeholder="First name"
                variant="flushed"
              />
              {isError.first_name ? (
                <FormErrorMessage>First Name is required.</FormErrorMessage>
              ) : (
                ""
              )}
            </FormControl>
            <FormControl isInvalid={isError.last_name}>
              <Input
                onChange={onChange}
                name="last_name"
                placeholder="Last name"
                variant="flushed"
              />
              {isError.last_name ? (
                <FormErrorMessage>Last Name is required.</FormErrorMessage>
              ) : (
                ""
              )}
            </FormControl>
            <FormControl isInvalid={isError.phone}>
              <Input
                onChange={onChange}
                name="phone"
                type="tel"
                placeholder="Telephone (optional)"
                variant="flushed"
              />
              {isError.phone ? (
                <FormErrorMessage>Invalid Phone Number</FormErrorMessage>
              ) : (
                ""
              )}
            </FormControl>
          </SimpleGrid>
          <Spacer />
        </Flex>
      </Center>
      <Spacer />
      <QuestionFooter
        isEnd
        disableShowResults={!checkFormStarted() ? true : checkFormComplete()}
        questionId={totalPages}
        questions={questions}
        answers={answers}
        userFormData={userFormData}
      />
    </>
  );
};

export default UserForm;
