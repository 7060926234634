import { Flex, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { useIsMobile } from "../../utils/isMobile";

const AppContainer = (props) => {
  const [isMobile] = useIsMobile();
  const Header = () => {
    return (
      <SimpleGrid
        columns={isMobile ? 1 : 3}
        gap="1em"
        marginTop={isMobile ? "-10px" : "-20px"}
      >
        <Image
          w={isMobile ? "120px" : "160px"}
          src="/logo.svg"
          alt="Expect logo"
        />
        {props.header}
      </SimpleGrid>
    );
  };

  return (
    <Flex
      width={isMobile ? "auto" : ""}
      minH={isMobile ? "-webkit-fill-available" : "100vh"}
      direction="column"
      gap={"2em"}
      id="app-container"
      padding={isMobile ? "15px" : "50px"}
    >
      <Header />
      {props.children}
    </Flex>
  );
};

export default AppContainer;
