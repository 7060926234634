import { meanBy, nth } from "lodash/fp";

export const getStage = (aggregate) => {
  if (aggregate < 3.5) return 0;
  else if (aggregate < 6.5) return 1;
  else if (aggregate < 8) return 2;
  else return 3;
};

export const getScores = (questions, answers, categories) => {
  questions.forEach((question, index) => (question.answer = answers[index]));

  const categoryScores = categories.map((category) => {
    const categoryQuestions = questions.filter(
      (question) => question.categories.includes(category) && question.answer
    );

    // TODO: make this an object
    return [
      category,
      categoryQuestions.length
        ? (categoryQuestions
            ?.map((question) => question.answer)
            .reduce((total, nextVal) => total + Number(nextVal), 0) /
            (categoryQuestions.length || 1) +
            2) *
          2.5
        : 0,
    ];
  });

  const aggregate = meanBy(nth(1), categoryScores);
  const stageIndex = getStage(aggregate);

  return { stageIndex, categoryScores, aggregate };
};
