import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import ProgressBar from "./ProgressBar";

const QuestionHeader = (props) => {
  return (
    <>
      <Flex
        gap="12px"
        opacity={0.7}
        alignItems="center"
        justifyContent="center"
      >
        <Image
          src={"/icons/sections/" + props.category + ".svg"}
          alt={props.category}
        />
        <Text color="expect.sky">{props.category}</Text>
      </Flex>
      <ProgressBar
        question={props.questionId}
        max={props.questionsLength}
        aria-label="questionnaire progress"
      />
    </>
  );
};

export default QuestionHeader;
