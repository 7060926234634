import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useIsMobile } from "../../utils/isMobile";

export const Version = () => {
  const [isMobile] = useIsMobile();
  return (
    <Box
      sx={{
        gridColumn: "2 / span 3",
        textAlign: "end",
        alignSelf: "center",
      }}
    >
      <Text
        fontSize={isMobile ? "12px" : "16px"}
        color="onSurface.light.medium"
      >
        v2.0 published on 20 Jun 2023
      </Text>
    </Box>
  );
};
