export const categories = [
  "Awareness and Communication",
  "Emission Data",
  "Engagement",
  "Financial Commitment",
  "Energy Efficiency",
  "Supply Chain",
  "Strategy and Roadmap",
  "Analysis and Action",
  "Technology and Automation",
];
