export const getTitle = (score) => {
  if (score >= 0 && score < 3.5) {
    return "Climate Enthusiast";
  }
  if (score >= 3.5 && score < 6.5) {
    return "Climate Conscious";
  }
  if (score >= 6.5 && score < 8) {
    return "Climate Influencer";
  } else return "Climate Leader";
};
