export const filterByUpdatedAt = (
  responseObj,
  startDate,
  startMonth,
  startYear,
  dayFilter
) => {
  return responseObj?.filter((obj) => {
    if (obj.updated_at) {
      const updatedAt = new Date(obj.updated_at);
      if (
        updatedAt.getDate() === startDate &&
        updatedAt.getMonth() === startMonth &&
        updatedAt.getFullYear() &&
        startYear &&
        dayFilter === "today"
      ) {
        return true;
      } else if (
        updatedAt.getMonth() === startMonth &&
        updatedAt.getFullYear() === startYear &&
        dayFilter === "monthly"
      ) {
        return true;
      } else if (
        updatedAt.getDate() >= startDate - 6 &&
        updatedAt.getDate() <= startDate &&
        updatedAt.getMonth() === startMonth &&
        updatedAt.getFullYear() === startYear &&
        dayFilter === "weekly"
      ) {
        return true;
      }
      return false;
    } else return false;
  });
};
