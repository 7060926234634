import React, { useState } from "react";
import { Center, Flex, Button, Text, useToast } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import { generateOTP, validateOTP } from "../api/sendResults";

const OtpValidation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const [otp, setOtp] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const customInputStyle = {
    marginRight: "10px",
    width: "3rem",
    height: "3rem",
    borderRadius: "10px",
  };

  const { emailData, otpPayload } = location.state;

  const validateOtp = async () => {
    const { reportLinkId, score } = emailData;
    let validateOTPPayload = {
      survey_id: reportLinkId,
      score,
      otp,
    };
    let result = await validateOTP(validateOTPPayload);
    if (result.ok) navigate("/success");
    else {
      toast({
        title: "Error",
        description: "Wrong or Invalid OTP!",
        status: "error",
        duration: 5000,
      });
    }
  };

  const verifyEmail = async () => {
    let result = await generateOTP(otpPayload);
    if (result.ok) {
      toast({
        title: "Success",
        description: "Email Sent Successfully!",
        status: "success",
        duration: 5000,
      });
      setResendCount(resendCount + 1);
    } else {
      toast({
        title: "Error",
        description: "Failed to Send Email!",
        status: "error",
        duration: 5000,
      });
    }
  };

  return (
    <>
      <Center h="100%">
        <Center w="580px" flexDirection="column" h="100%" marginTop="5%">
          <Text align="center" fontSize="3xl" mb="30px">
            Please enter the one time password to verify your email address.
          </Text>
          <Flex>
            <Text marginBottom={4} marginRight={1}>A code has been sent to </Text>
            <Text color="sky.500">{emailData?.receiver_email}</Text>
          </Flex>
          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            inputStyle={customInputStyle}
          />
          <Button
            width={150}
            bg="sky.500"
            _hover={{ bg: "sky.600" }}
            _active={{ bg: "sky.700" }}
            onClick={validateOtp}
            marginTop="2rem"
          >
            Validate
          </Button>
          <Flex marginTop={"2rem"}>
            <Text fontSize="xs" alignSelf={"center"}>
              Didn't get the code?
            </Text>
            <Button
              width={150}
              color="sky.500"
              onClick={verifyEmail}
              variant="link"
              isDisabled={resendCount === 3}
            >
              Resend
              <Text marginLeft={1} color="sky.500">
                ({resendCount}/3)
              </Text>
            </Button>
          </Flex>
        </Center>
      </Center>
    </>
  );
};

export default OtpValidation;
