import React, { useContext, useEffect, useState } from "react";
import Search from "./Search";
import { UserInfo } from "./UserInfo";
import { Spinner, Box } from "@chakra-ui/react";
import DayWiseUserInfo from "./DayWiseUserInfo";
import { getResponsesOfUser, getUsers } from "../api/getUsers";
import { filterByUpdatedAt } from "../../utils/filterByDate";
import { AuthContext } from "../../AuthProvider";
import { Navigate } from "react-router-dom";
import { isEmpty } from "lodash/fp";

const Dashboard = ({
  setHeader,
  setResult,
  selectedUser,
  setSelectedUser,
  setShowLoader,
  setResponseList,
  setDayFilter,
  dayFilter,
  responseList,
  showLoader,
}) => {
  const [users, setUsers] = useState();
  const { signedIn } = useContext(AuthContext);

  useEffect(() => {
    setHeader(<></>);
  }, [setHeader]);

  useEffect(() => {
    if (!signedIn) return;

    setShowLoader(true);

    async function getFilterBasedUsers() {
      const date = new Date();
      let { survey_list } = await getUsers("");
      const res = filterByUpdatedAt(
        survey_list,
        date.getDate(),
        date.getMonth(),
        date.getFullYear(),
        dayFilter
      );

      setUsers(res);
      setShowLoader(false);
    }
    getFilterBasedUsers();
  }, [dayFilter, signedIn, setShowLoader]);

  useEffect(() => {
    if (!isEmpty(selectedUser)) {
      setShowLoader(true);
      async function getQuestionResponseOfUser() {
        let { survey_response_list } = await getResponsesOfUser(
          selectedUser.id
        );
        setResponseList(survey_response_list);
        setShowLoader(false);
      }
      getQuestionResponseOfUser();
    }
  }, [selectedUser, setResponseList, setShowLoader]);

  if (!signedIn) return <Navigate to="/login" />;

  return (
    <>
      <Search setResult={setResult} setSelectedUser={setSelectedUser} />
      <>
        <DayWiseUserInfo setDayFilter={setDayFilter} dayFilter={dayFilter} />
        {users?.map((item, idx) => {
          return (
            <UserInfo
              user={item}
              key={idx}
              setSelectedUser={setSelectedUser}
              responseList={responseList}
              setHeader={setHeader}
            />
          );
        })}
      </>
      {showLoader && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
    </>
  );
};

export default Dashboard;
