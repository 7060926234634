import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveInitialData } from "../api/saveInitialData";
import QuestionFooter from "./QuestionFooter";
import QuestionHeader from "./QuestionHeader";
import QuestionNumber from "./QuestionNumber";
import { useIsMobile } from "../../utils/isMobile";
import { Industries } from "../../constants/industries";
import { positions } from "../../constants/positions";

const InitialForm = ({
  setHeader,
  questions,
  userFormData,
  setUserFormData,
}) => {
  const totalPages = questions.length + 2;
  const { company, position, industry } = Object(userFormData);
  const [isMobile] = useIsMobile();
  const [formStart, setFormStart] = useState({
    company: false,
    position: false,
    industry: false,
  });
  const [isError, setIsError] = useState({
    company: false,
    position: false,
    industry: false,
  });
  const navigate = useNavigate();

  const checkFormStarted = () => {
    return formStart.company && formStart.industry && formStart.position;
  };

  useEffect(() => {
    setHeader(
      <QuestionHeader
        questionId={1}
        category="Your details"
        questionsLength={totalPages}
      />
    );
  }, [setHeader, totalPages]);

  const validations = (name, value) => {
    if (value === "") {
      setIsError({
        ...isError,
        [name]: true,
      });
    } else {
      setIsError({
        ...isError,
        [name]: false,
      });
      setUserFormData({
        ...userFormData,
        [name]: value,
      });
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormStart({
      ...formStart,
      [name]: true,
    });
    validations(name, value);
  };

  const checkFormComplete = () => {
    return Object.values(isError).some((value) => value === true);
  };

  return (
    <>
      <Center flex={1}>
        <Flex
          w="900px"
          direction="column"
          textAlign={"center"}
          gap={isMobile ? "40px" : "72px"}
        >
          <QuestionNumber number={1} max={totalPages} />
          <Heading
            fontSize={["24px", "36px", "38px"]}
            fontWeight="normal"
            textAlign="center"
            m="auto"
          >
            Your Details
          </Heading>
          <FormControl isInvalid={isError.company}>
            <Input
              onChange={onChange}
              name="company"
              isRequired
              maxLength={100}
              placeholder="Company"
              variant="flushed"
              width="100%"
            />
            {isError.company ? (
              <FormErrorMessage>Company name is required.</FormErrorMessage>
            ) : null}
          </FormControl>
          <HStack gap={isMobile ? "30px" : "56px"}>
            <FormControl isInvalid={isError.industry}>
              <Select
                placeholder="Industry"
                variant="flushed"
                name="industry"
                onChange={onChange}
              >
                {Industries.map((item, idx) => (
                  <option key={idx}>{item}</option>
                ))}
              </Select>
              {isError.industry ? (
                <FormErrorMessage>Industry is required.</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl isInvalid={isError.position}>
              <Select
                placeholder="Designation"
                variant="flushed"
                name="position"
                onChange={onChange}
              >
                {positions.map((item, idx) => (
                  <option key={idx}>{item}</option>
                ))}
              </Select>
              {isError.position ? (
                <FormErrorMessage>Designation is required.</FormErrorMessage>
              ) : null}
            </FormControl>
          </HStack>
        </Flex>
      </Center>
      {!isMobile && <Spacer />}
      <QuestionFooter
        isStart
        questionId={1}
        disableNext={!checkFormStarted() ? true : checkFormComplete()}
        rightSide={
          <Button
            variant="primary"
            disabled={!checkFormStarted() ? true : checkFormComplete()}
            onClick={() => {
              const res = checkFormComplete();
              if (!res) {
                saveInitialData({ company, industry, position });
                navigate("/questions/2");
              }
            }}
          >
            Next
          </Button>
        }
      />
    </>
  );
};

export default InitialForm;
