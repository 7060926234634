import React, { useState, useEffect } from "react";
import { Box, Flex, Center, Input } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

import SearchResults from "./SearchResults";
import { getUsers } from "../api/getUsers";
import { debounce } from "lodash";
import { useIsMobile } from "../../utils/isMobile";

const Search = ({ setResult, setSelectedUser }) => {
  const [queryText, setQueryText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => setQueryText(e.target.value);
  const [isMobile] = useIsMobile();

  useEffect(() => {
    if (!queryText) {
      setSearchResults([]);
    }

    async function getUsersResult() {
      if (queryText.length > 0) {
        setSearchResults([]);
        let { survey_list } = await getUsers({ search: queryText });
        if (survey_list.length > 0) {
          setSearchResults(survey_list);
        }
      }
    }
    getUsersResult();
  }, [queryText]);

  return (
    <Box
      sx={{
        rounded: "lg",
        overflow: "hidden",
        bg: "transparent",
        shadow: "lg",
        maxW: "500px",
        width: "90%",
        mx: "auto",
      }}
    >
      <Flex pos="relative" align="strech">
        <Input
          type="text"
          placeholder="Search Users"
          sx={{
            w: "100%",
            h: "55px",
            pl: "55px",
            fontWeight: "medium",
            outline: 0,
            backgroundColor: "gray.700",
          }}
          onKeyDown={debounce(handleChange, 300)}
        />

        <Center pos="absolute" left={7} h="55px">
          <SearchIcon color="rgba(0, 168, 240, 1)" boxSize="20px" />
        </Center>
      </Flex>

      {queryText && (
        <Box
          position="absolute"
          width={isMobile ? "85%" : "35%"}
          maxH="70vh"
          p="0"
          overflowY="auto"
          zIndex={9999}
        >
          <Box px={4}>
            <Box borderTopWidth="1px" pt={2} pb={4}>
              <SearchResults
                searchResults={searchResults}
                setQueryText={setQueryText}
                setSelectedUser={setSelectedUser}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Search;
