export const saveInitialData = async ({ company, industry, position }) => {
  try {
    const response = await fetch(
      "https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/survey",
      {
        method: "POST",
        body: JSON.stringify({ company, industry, position }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    localStorage.setItem("surveyId", data.id);
    localStorage.setItem("encryptedSurveyId", data.encrypted_id);
  } catch (error) {
    console.log("Error: ", error);
  }
};
