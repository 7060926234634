export const Industries = [
  "Information Technology(IT)",
  "Healthcare",
  "Finance and Banking",
  "Manufacturing",
  "Retail",
  "Automotive",
  "Telecommunications",
  "Energy and Utilities",
  "Education",
  "Hospitality and Tourism",
  "Construction",
  "Transportation and Logistics",
  "Media and Entertainment",
  "Aerospace and Defense",
  "Agriculture and Food",
  "Pharmaceuticals",
  "Real Estate",
  "E-commerce",
  "Professional Services",
  "Non-profit Organisations",
];
