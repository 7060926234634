import Cookies from "js-cookie";
import { handleUnauthorized } from "../../utils/token";

export const getUsers = async (query) => {
  try {
    const response = await fetch(
      `https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/survey${
        query === "" ? "" : "?"
      }` + new URLSearchParams(query),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    handleUnauthorized(response);
    let users = await response.json();
    return users;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const getResponsesOfUser = async (id) => {
  try {
    const response = await fetch(
      `https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/survey/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    handleUnauthorized(response);
    let data = await response.json();
    return data;
  } catch (error) {
    console.log("Error: ", error);
  }
};
