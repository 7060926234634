import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useIsMobile } from "../../utils/isMobile";

const QuestionNumber = (props) => {
  const [isMobile] = useIsMobile();

  return (
    <Box align="center" width="100%" height={isMobile ? "7vh" : "10vh"}>
      <Text as="span" color="secondary.secondary" width="100%">
        {props.number}{" "}
        <Text display={"inline"} opacity={0.7}>
          / {props.max}
        </Text>
      </Text>
    </Box>
  );
};

export default QuestionNumber;
