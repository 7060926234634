import { Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useIsMobile } from "../../utils/isMobile";

const EmailSuccess = ({ setHeader }) => {
  const [isMobile] = useIsMobile();

  useEffect(() => {
    setHeader(<></>);
  }, [setHeader]);
  return (
    <>
      <VStack
        textAlign="center"
        maxW="750px"
        flex={1}
        gap="20px"
        align="center"
        mx="auto"
        pt={10}
      >
        <Image
          src="/icons/Counter.svg"
          width={isMobile ? "80px" : "100px"}
          height={isMobile ? "80px" : "100px"}
          alt="success"
        />
        <Text
          color="rgba(0, 168, 240, 1)"
          fontSize={isMobile ? "40px" : "50px"}
        >
          Great job!
        </Text>
        <Text fontSize={isMobile ? "18px" : "20px"}>
          You've completed the assessment.
        </Text>
        <Text fontSize={isMobile ? "18px" : "20px"} lineHeight="36px" flex={1}>
          Your assessment will be emailed to you directly, so please keep an eye
          out in your inbox.
        </Text>
      </VStack>
    </>
  );
};

export default EmailSuccess;
