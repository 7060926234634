export const sendResults = async (userInfo, results, questionAnswerObj) => {
  const surveyId = Number(localStorage.getItem("surveyId"));
  const encryptedSurveyId = localStorage.getItem("encryptedSurveyId");

  const formattedResults = results.map((result) => [
    formatBody(result[0]),
    result[1],
  ]);

  const submissionData = {
    ...userInfo,
    ...Object.fromEntries(formattedResults),
    survey_responses: questionAnswerObj.map((x) => {
      if (x.answer === "") return { ...x, answer: "-3" };
      return x;
    }),
  };

  try {
    const response = await fetch(
      `https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/survey/${encryptedSurveyId}`,
      {
        method: "PATCH",
        body: JSON.stringify(submissionData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error: ", error);
    return [];
  }
};

const formatBody = (string) => {
  // Formats the results object as expected by the server
  return string
    .split(" ")
    .map((word) => word.toLowerCase())
    .join("_");
};

export const sendEmailInfo = async (submissionData) => {
  try {
    const response = await fetch(
      `https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/send-email`,
      {
        method: "POST",
        body: JSON.stringify(submissionData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error: ", error);
    return [];
  }
};

export const generateOTP = async (payload) => {
  try {
    const response = await fetch(
      `https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/send-verification-email`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error: ", error);
    return {};
  }
};

export const validateOTP = async (payload) => {
  try {
    const response = await fetch(
      `https://diagnostic-api-vtd5ekus7a-oa.a.run.app/v1/verify-and-email-results`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error: ", error);
    return [];
  }
};
