import React from "react";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  VStack,
  Tbody,
} from "@chakra-ui/react";
import { useIsMobile } from "../../utils/isMobile";

const QuestionsGrid = ({ result }) => {
  const answer = {
    "-2": "Strongly Disagree",
    "-1": "Disagree",
    0: "Neutral",
    1: "Agree",
    2: "Strongly Disagree",
  };
  const [isMobile] = useIsMobile();

  return (
    <>
      <VStack maxW="1000px" flex={1} gap="10px" align="center" mx="auto">
        <TableContainer width="100%" whiteSpace="normal">
          <Table size="lg" variant="simple">
            <Thead>
              <Tr>
                <Th>Question</Th>
                <Th>Response</Th>
              </Tr>
            </Thead>
            <Tbody>
              {result?.length > 0 &&
                result.map((obj, index) => {
                  return (
                    <Tr
                      fontSize={isMobile ? "14px" : "16px"}
                      key={obj.question}
                    >
                      <Td>{obj.question}</Td>
                      <Td>{answer[obj.answer]}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </>
  );
};

export default QuestionsGrid;
