import React from "react";
import { Button, Flex } from "@chakra-ui/react";

const DayWiseUserInfo = ({ setDayFilter, dayFilter }) => {
  return (
    <>
      <Flex width={"100%"} justifyContent={"center"} gap={"25px"}>
        <Button
          variant={dayFilter === "today" ? "primary" : "solid"}
          padding={"10px"}
          onClick={() => setDayFilter("today")}
        >
          Today
        </Button>
        <Button
          variant={dayFilter === "weekly" ? "primary" : "solid"}
          padding={"10px"}
          onClick={() => setDayFilter("weekly")}
        >
          Weekly
        </Button>
        <Button
          variant={dayFilter === "monthly" ? "primary" : "solid"}
          padding={"10px"}
          onClick={() => setDayFilter("monthly")}
        >
          Monthly
        </Button>
      </Flex>
    </>
  );
};

export default DayWiseUserInfo;
