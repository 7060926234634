import { Flex, Progress, Text } from "@chakra-ui/react";
import React from "react";
import { useIsMobile } from "../../utils/isMobile";

const ProgressBar = (props) => {
  const [isMobile] = useIsMobile();
  const percentageProgress =
    window.location.pathname === "/final"
      ? 100
      : Math.round((Number(props.question - 1) / props.max) * 100);

  return (
    <Flex alignItems={"center"} gap="32px" w="100%">
      <Progress
        value={percentageProgress}
        opacity={0.7}
        size="xs"
        w="100%"
        borderRadius={"100px"}
      />
      {!isMobile && (
        <Text
          color={"secondary.secondary"}
          opacity={0.7}
          fontSize={"32px"}
          fontWeight={500}
        >
          {percentageProgress}%
        </Text>
      )}
    </Flex>
  );
};

export default ProgressBar;
