import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import { Box } from "@chakra-ui/react";

const RadarChart = ({ scores, benchmark }) => {
  HC_more(Highcharts);

  const options = {
    chart: {
      backgroundColor: "transparent",
      polar: true,
      type: "line",
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    pane: {
      size: "65%",
      margin: 30,
    },
    colors: ["#ffffff", "#00A8F0"],
    xAxis: {
      categories: scores.map((score) => score[0]),
      tickmarkPlacement: "on",
      gridLineColor: "rgba(255, 255, 255, 0.35)",
      lineWidth: 0.5,
      labels: {
        useHTML: true,
        distance: 30,
        formatter: (e) => {
          return `<div style="width: 32px; height: 32px; opacity: 0.7;">
            <img src="/icons/sections/${e.value}.svg" alt="${e.value}" width="32" />
          </div>`;
        },
      },
    },
    yAxis: {
      tickInterval: 2.5,
      gridLineInterpolation: "circle",
      gridLineColor: "rgba(255, 255, 255, 0.35)",
      lineWidth: 0,
      min: 0,
      max: 10,
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Benchmark",
        data: Array(scores.length).fill(benchmark),
        dashStyle: "ShortDot",
        lineWidth: 2,
      },
      {
        name: "Score",
        data: scores.map((score) => score[1]),
        lineWidth: 4,
      },
    ],
    plotOptions: {
      series: {
        linecap: "square",
        states: {
          hover: {
            enabled: false,
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            pane: {
              size: "60%",
            },
          },
        },
      ],
    },
  };

  return (
    <Box id="radar" marginBottom={"40px"}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
};

export default RadarChart;
