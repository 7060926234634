import React from "react";

import { Box, Text } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

const SearchResults = ({ searchResults, setSelectedUser, setQueryText }) => {
  const navigate = useNavigate();
  const sendSelectedUser = (user) => {
    setSelectedUser(user);
    setQueryText("");
    navigate("/dashboard/user-results");
  };

  return (
    <Box backgroundColor="gray.700" borderRadius="5px">
      {searchResults.length > 0 &&
        searchResults.map((user) => (
          <Box
            key={nanoid()}
            transition="0.3s"
            _hover={{
              opacity: "0.5",
              cursor: "pointer",
            }}
            p="5px 5px"
            onClick={() => sendSelectedUser(user)}
          >
            <Text fontSize="1xl" color="white" noOfLines={1}>
              {user.first_name} {user.last_name}
            </Text>
          </Box>
        ))}
    </Box>
  );
};

export default SearchResults;
