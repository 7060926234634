import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Version } from "../Version/Version";
import { useIsMobile } from "../../utils/isMobile";

const StartPage = ({ setHeader }) => {
  const [isMobile] = useIsMobile();

  useEffect(() => {
    setHeader(<Version />);
  }, [setHeader]);

  const Footer = () => {
    return (
      <Flex flexDirection="column" gap="30px">
        <Flex justify="space-between" alignItems="center">
          <Flex align="center" gap="8px">
            <Image src="/icons/timer.svg" alt="clock icon" />
            <Text
              fontSize={isMobile ? "12px" : "16px"}
              color="secondary.secondary"
              opacity={0.7}
              paddingTop="-20px"
            >
              Takes 3-5 minutes
            </Text>
          </Flex>
          {isMobile ? (
            <Link to="/questions/1">
              <Button
                rightIcon={<Image src="/icons/arrowRight.svg" />}
                variant="primary"
              >
                Start
              </Button>
            </Link>
          ) : (
            <Link to="/questions/1">
              <Button
                variant="primary"
                size="lg"
                rightIcon={<Image src="/icons/arrowRight.svg" />}
              >
                Start
              </Button>
            </Link>
          )}
        </Flex>
        <Text fontSize="10px" width="auto" height="auto" textAlign="left">
          © 2023 Exponential Climate Technologies Ltd. All rights reserved The
          following are trademarks of Exponential Climate Technologies Ltd; UNA™
          as our Climate Action Platform, Metamorphosis™ as our design led
          customer process, Expect™ as our company brand name, Expectopedia™ as
          our knowledge base (open sourced). For further information on out T
          and C's please visit: expectai.com
        </Text>
      </Flex>
    );
  };

  return (
    <>
      <VStack
        maxW="750px"
        flex={1}
        gap={isMobile ? "40px" : "28px"}
        align="left"
        mx="auto"
        height="auto"
      >
        <Heading
          lineHeight={1}
          fontSize={isMobile ? "40px" : "78px"}
          fontWeight={300}
        >
          Expect your{" "}
          <Text as="span" fontWeight="bold" color="primary.primary">
            Carbon Maturity{" "}
          </Text>
          level in Minutes
        </Heading>
        <Text fontSize={isMobile ? "15px" : "20px"} lineHeight="36px" flex={1}>
          Let us help you to better understand which stage you are at on your
          path to Net Zero with our free-to-use diagnostic tool
        </Text>
        <Box marginBottom={["-10px", null, null, null, "-10px"]}>
          <Footer />
        </Box>
      </VStack>
    </>
  );
};

export default StartPage;
