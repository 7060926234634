import { Input, Center, Flex, Button, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { isEmpty } from "lodash";
import { AuthContext } from "../../AuthProvider";
import { Navigate } from "react-router-dom";

export const AdminLogin = () => {
  const [userData, setUserData] = useState({});
  const [showError, setShowError] = useState(false);
  const { signedIn, signIn } = useContext(AuthContext);

  if (signedIn) return <Navigate to="/dashboard" />;

  const disableSubmit =
    isEmpty(userData.username) || isEmpty(userData.password);

  const onChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const submit = async () => {
    let { username, password } = userData;

    try {
      await signIn(username, password);
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <>
      <Center h="100%">
        <Flex
          direction={"column"}
          gap="2em"
          alignItems={"center"}
          padding="32px"
          borderColor="primary.primary"
          borderWidth={"1px"}
          borderRadius="16px"
        >
          <Input
            onChange={onChange}
            placeholder="Username"
            type="text"
            variant="flushed"
            name="username"
            size="lg"
            borderColor={"onSurface.light.low"}
            tabIndex={1}
            required
          />
          <Input
            onChange={onChange}
            placeholder="Password"
            type="password"
            variant="flushed"
            name="password"
            size="lg"
            borderColor={"onSurface.light.low"}
            tabIndex={2}
            required
          />
          {showError && <Text color="red">Wrong Credentials</Text>}
          <Button disabled={disableSubmit} onClick={submit}>
            Submit
          </Button>
        </Flex>
      </Center>
    </>
  );
};
