export const positions = [
  "Chief Executive Officer (CEO)",
  "Chief Operating Officer (COO)",
  "Chief Financial Officer (CFO)",
  "Chief Technology Officer (CTO)",
  "Chief Marketing Officer (CMO)",
  "Chief Human Resources Officer (CHRO)",
  "Director",
  "Manager",
  "Team Lead",
  "Supervisor",
  "Project Manager",
  "Software Engineer/Developer",
  "Sales Executive/Manager",
  "Marketing Specialist/Manager",
  "Human Resources Specialist/Manager",
  "Finance Analyst/Manager",
  "Customer Support Representative/Manager",
  "Administrative Assistant",
  "Operations Coordinator/Manager",
  "Quality Assurance Engineer/Manager",
];
