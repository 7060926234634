import {
  Box,
  Center,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionFooter from "./QuestionFooter";
import QuestionHeader from "./QuestionHeader";
import QuestionNumber from "./QuestionNumber";
import { useIsMobile } from "../../utils/isMobile";

const QuestionPage = (props) => {
  const setHeader = props.setHeader;
  const { page } = useParams();
  const answers = props.answers;
  const setAnswers = props.setAnswers;
  const [currentAnswer, setCurrentAnswer] = useState("-3");
  const questions = props.questions;
  const navigate = useNavigate();
  const [isMobile] = useIsMobile();

  const currentQuestion = questions[Number(page) - 2];
  const totalPages = questions?.length + 2;

  useEffect(() => {
    setHeader(
      <QuestionHeader
        questionId={Number.parseInt(page)}
        category={questions?.length > 1 ? currentQuestion?.categories[0] : ""}
        questionsLength={totalPages}
      />
    );
    setCurrentAnswer(answers[Number(page) - 1]);
  }, [
    setHeader,
    page,
    answers,
    currentQuestion?.categories,
    questions.length,
    totalPages,
  ]);

  const setQuestionValue = (questionId, newValue) => {
    answers[questionId - 1] = newValue;
    setCurrentAnswer(newValue);
    setAnswers(answers);
  };

  const onChange = (event) => {
    setQuestionValue(page - 1, event);
    setTimeout(() => navigate("/questions/" + (Number(page) + 1)), 500);
  };

  if (questions.length < 2)
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );

  return (
    <>
      <Box
        maxW={["100%", "100%", "100%", "100%", "900px"]}
        boxSizing="border-box"
        gap={isMobile ? "20px" : "auto"}
        align={["left", "left", "left", "left", "center"]}
        alignSelf={["left", "left", "left", "left", "center"]}
        position={"relative"}
      >
        <QuestionNumber number={page} max={totalPages} />
        <Heading
          fontSize={["18px", "20px", "28px", "25px", "32px"]}
          fontWeight="normal"
          textAlign={["left", "left", "left", "left", "center"]}
          h={["auto", "auto", "auto", "auto", "150px"]}
        >
          {currentQuestion?.question}
        </Heading>
        <RadioGroup
          mt={["5%", "5%", "3%", "3%", "1%"]}
          size={["md", "lg", "lg", "lg", "xl"]}
          onChange={onChange}
          value={currentAnswer}
        >
          <Flex
            w="100%"
            direction={["column", "column", "column", "column", "row"]}
            justify="center"
            h={"auto"}
            gap={isMobile ? "10px" : "40px"}
          >
            <Radio value="-2" minW="130px" h="auto">
              <Text
                marginInline={isMobile ? "10px" : null}
                textAlign={["left", "left", "left", "left", "center"]}
                w={["auto", "auto", "auto", "auto", "160px"]}
                fontSize={["16px", "18px", "22px", "20px", "16px"]}
                color={"white"}
              >
                {currentQuestion.option1}
              </Text>
            </Radio>
            <Radio value="-1" minW="130px" h="auto">
              <Text
                marginInline={isMobile ? "10px" : null}
                textAlign={["left", "left", "left", "left", "center"]}
                w={["auto", "auto", "auto", "auto", "160px"]}
                fontSize={["16px", "18px", "22px", "20px", "16px"]}
                color={"white"}
              >
                {currentQuestion.option2}
              </Text>
            </Radio>
            <Radio value="0" minW="130px" h="auto">
              <Text
                marginInline={isMobile ? "10px" : null}
                textAlign={["left", "left", "left", "left", "center"]}
                w={["auto", "auto", "auto", "auto", "160px"]}
                fontSize={["16px", "18px", "22px", "20px", "16px"]}
                color={"white"}
              >
                {currentQuestion.option3}
              </Text>
            </Radio>
            <Radio value="1" minW="130px" h="auto">
              <Text
                marginInline={isMobile ? "10px" : null}
                textAlign={["left", "left", "left", "left", "center"]}
                w={["auto", "auto", "auto", "auto", "160px"]}
                fontSize={["16px", "18px", "22px", "20px", "16px"]}
                color={"white"}
              >
                {currentQuestion.option4}
              </Text>
            </Radio>
            <Radio value="2" minW="130px" h="auto">
              <Text
                marginInline={isMobile ? "10px" : null}
                textAlign={["left", "left", "left", "left", "center"]}
                w={["auto", "auto", "auto", "auto", "160px"]}
                fontSize={["16px", "18px", "22px", "20px", "16px"]}
                color={"white"}
              >
                {currentQuestion.option5}
              </Text>
            </Radio>
          </Flex>
        </RadioGroup>
      </Box>
      <QuestionFooter questionId={page} disableNext={!currentAnswer} />
    </>
  );
};

export default QuestionPage;
