import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { signIn as signInApi } from "./components/api/signIn";

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [signedIn, setSignedIn] = useState();

  useEffect(() => {
    const existingToken = Cookies.get("token");
    if (existingToken) setSignedIn(true);
  }, []);

  async function signIn(username, password) {
    try {
      const response = await signInApi({ username, password });

      if (!response.ok) throw new Error(response.statusText);

      const { token } = await response.json();
      setSignedIn(true);

      Cookies.set("token", token, {
        secure: true,
        sameSite: "Strict",
        expires: 1, // same as backend
      });
    } catch (err) {
      throw err;
    }
  }

  async function signOut() {
    setSignedIn(null);
    Cookies.remove("token");
  }

  return (
    <AuthContext.Provider value={{ signedIn, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
